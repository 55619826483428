/*
    Simple wrapper for Font Awesome Icons

    Props:

    className: allows you to pass class in for color / size

    icon: the FontAwesome icon name

    size: overwrites size of icon using font-size
 */

import classes from './FAIcon.module.scss';

const FAIcon = (props) => {

    const className = props.className || classes.defaultIconClass;

    return (
        <i className={`fa fa-${props.icon} ${className}`} style={{fontSize:(props.size)?`${props.size}px`: ""}} ></i>
    )

}
 export default FAIcon;
