import React from 'react';

import classes from './TopMenuChevron.module.scss';
import FAIcon from "../UI/Icons/FAIcon";

const TopMenuCheveron = (props) => {

    const clazz = [classes.cheveron];

    if(props.arrowUp){
        clazz.push(classes.flipVertical)
    }

    return (
        // <img className={clazz.join(' ')} src={require("../../assets/images/icons/ico-chevron-reg-down.svg").default} alt=""/>
        // <i className={"fa fa-chevron-down "+ clazz.join(' ')}></i>
        <FAIcon className={clazz.join(' ')} icon={"chevron-down"}></FAIcon>
    );
};

export default TopMenuCheveron;
